.messagesContainer {
  color: white;
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  background-color: #3a3c42;
  display: inline-grid;
  overflow-x: hidden;
  overflow-y: scroll;
}
.messagesContainer::-webkit-scrollbar {
  display: none;
}

.innerMsg {
  margin-bottom: 46px;
}

.messagesDisplay {
  list-style-type: none;
  width: 250px;
  height: 100%;
  flex-direction: column;
  align-items: center;
}

.singleMessageDisplay {
  color: white;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 82rem;
  margin: 1rem;
  border: solid transparent 1px;
  overflow: hidden;
}

.singleMessageDisplay:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px;
}

.username {
  margin: 10px;
  padding: 5px;
  font-weight: bold;
  font-size: 17px;
  font-family: "Quicksand", sans-serif;
}

.fa-user {
  margin-right: 5px;
}

.msg-body {
  margin-left: 20px;
  font-family: "Quicksand", sans-serif;
}

.fa-pen-to-square {
  right: 12%;
  margin-right: 20px;
  cursor: pointer;
}

.fa-trash-can {
  cursor: pointer;
  right: 10%;
  z-index: 0;
}

.edit-del {
  display: flex;
  justify-content: end;
  width: 96%;
}

@media screen and (max-width: 1900px) {
  .edit-del {
    display: flex;
    justify-content: start;
  }
}

.createMessageForm {
  color: red;
  position: fixed;
  bottom: 0;
  width: 200px;
}

.create-message {
  width: 68.8vw;
  height: 5vh;
  background-color: #393a3e;
  color: white;
  z-index: 1;
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
}

.edit-form {
  width: 100px;
  height: 100px;
}

.send-button {
  background-color: red;
  height: 30px;
  position: fixed;
  top: 95.5%;
  right: 13%;
  background-color: white;
  cursor: pointer;
  width: 5%;
  z-index: 1;
  border-radius: 20px;
  font-weight: bold;
}

.send-button:hover {
  background-color: black;
  color: white;
}

.memberli {
  list-style-type: none;
  cursor: pointer;
  margin-right: 2rem;
  color: white;
}

.memberli1 {
  color: white;
}

.memberli1:hover {
  font-size: x-large;
}

.updateTitle {
  color: white;
}

.editMessageForm {
  border: 2px solid red;
  width: 25rem;
  height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
  border: solid transparent 1px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px;
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(163, 175, 243, 1) 0%,
    rgba(220, 182, 232, 1) 100.2%
  );
}

.edit-message {
  padding-left: 30px;
  font-family: "Quicksand", sans-serif;
}

.form-body {
  text-align: center;
}
