.outContainer {
  width: 100%;
  height: 90vh;
  display: flex;
  flex: 1 1 auto;
  margin: 0px;
}

body {
  background-color: #202225;
  margin: 0px;
  height: 100vh;
}

.joinServerBtn {
  height: 2rem;
  border: none;
  color: white;
  border-radius: 9px;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  background-image: radial-gradient(
    circle 732px at 96.2% 89.9%,
    rgba(70, 66, 159, 1) 0%,
    rgba(187, 43, 107, 1) 92%
  );
}

.editBtn {
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  color: white;
  border: none;
  border-radius: 7px;
  height: 2rem;
  margin: 2px;
  background-image: radial-gradient(
    circle 732px at 96.2% 89.9%,
    rgba(70, 66, 159, 1) 0%,
    rgba(187, 43, 107, 1) 92%
  );
}

.publicServers,
.userLists {
  color: white;
  display: flex;
  width: 15%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  background-color: #202225;
  overflow: hidden;
  min-height: 0;
  font-family: "Quicksand", sans-serif;
}

.privateServers {
  color: white;
  display: flex;
  width: 15%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  background-color: #202225;
  min-height: 0;
  font-family: "Quicksand", sans-serif;
  overflow-y: auto;
  overflow-x: hidden;
}

.noMsg {
  margin-top: 1.2rem;
  font-family: "Quicksand", sans-serif;
  justify-content: center;
  text-align: center;
}

.updateTitle {
  margin-top: -1%;
  padding-top: 0px;
}

.updateServerForm {
  background-color: #2b2d32;
  margin-top: 0px;
}

.serverChannels {
  color: white;
  width: 15%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2b2d32;
  font-family: "Quicksand", sans-serif;
  overflow-x: hidden;
  overflow-y: scroll;
}
.serverChannels::-webkit-scrollbar {
  display: none;
}

.publicServersDisplay::-webkit-scrollbar {
  display: none;
}

.publicServersDisplay {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  width: 8rem;
  width: 200px;
  border: solid transparent 1px;
  font-family: "Quicksand", sans-serif;
  padding: 1px;
  align-items: center;
}

.privateServersDisplay {
  font-family: "Quicksand", sans-serif;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 8rem;
  height: 100%;
  border: solid transparent 1px;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.channelsDisplay {
  list-style-type: none;
  width: 10%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2b2d32;
  margin-left: 4rem;
  color: aqua;
}

.singleChannelDisplay {
  color: rgb(169, 166, 166);
  display: flex;
  flex-direction: column;
  height: 2rem;
  width: 6rem;
  margin: 1rem;
  background-color: #2b2d32;
  justify-content: space-evenly;
  align-items: center;
  border: none;
  cursor: pointer;
}

/* .singleChannelDisplay:hover {
  text-decoration: underline;
  font-size: large;
} */

.highlight-dark:focus {
  background-color: rgba(102, 105, 108, 0.4);
  opacity: 2;
  color: white;
}

.singleServerDisplay {
  font-family: "Quicksand", sans-serif;
  color: white;
  display: flex;
  flex-direction: column;
  height: 2rem;
  width: 100%;
  margin: 1rem;
  background-color: #202225;
  justify-content: center;
  border: none;
  overflow: hidden;
  cursor: pointer;
}

.channelList {
  display: flex;
  flex-direction: row;
}

.addServerLinkContainer {
  color: white;
  background-color: #2f3136;
  height: 3rem;
  text-decoration: none;
}

.inputChannel {
  border-radius: 8px;
  height: 1.7rem;
}

.inputUpdateServer {
  border-radius: 8px;
  height: 1.7rem;
  margin: 2px;
}

.editServerBtn2 {
  border-radius: 5px;
  border: none;
}

.switchbutton {
  margin-top: 1rem;
  padding: 0.8rem;
  font-family: "Roboto", sans-serif;
  border-radius: 7px;
  background-image: radial-gradient(
    circle 732px at 96.2% 89.9%,
    rgba(70, 66, 159, 1) 0%,
    rgba(187, 43, 107, 1) 92%
  );
  cursor: pointer;
  color: white;
  border: 0;
}

.addaserverbutt {
  background-color: whitesmoke;
  border: solid 1px;
  border-radius: 35px;
  font-family: "Courier New", Courier, monospace;
  margin: 2px;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  font-weight: bold;
}

.addaserverbutt:hover {
  background-color: #202225;
  color: green;
}

.right-menu {
  border-radius: 4px;
  padding: 5px;
  border: 1px solid black;
  box-sizing: border-box;
  background-image: radial-gradient(
    circle 732px at 96.2% 89.9%,
    rgba(70, 66, 159, 1) 0%,
    rgba(187, 43, 107, 1) 92%
  );
}

.feature-list {
  width: 35rem;
  height: 27rem;
  display: flex;
  flex-direction: column;
  font-family: "Quicksand", sans-serif;
  /* justify-content: center; */
  align-content: center;
  border-radius: 10px;
  border: solid transparent 1px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px;
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(163, 175, 243, 1) 0%,
    rgba(220, 182, 232, 1) 100.2%
  );
  color: rgb(43, 48, 68);
}

.feat-para {
  margin-left: 5%;
  margin-right: 3%;
}

.got-btn {
  font-family: "Quicksand", sans-serif;
  background-image: radial-gradient(
    circle 732px at 96.2% 89.9%,
    rgba(70, 66, 159, 1) 0%,
    rgba(187, 43, 107, 1) 92%
  );
  width: 20rem;
  border: 0;
  border-radius: 0.2rem;
  padding: 13px;
  color: white;
  font-size: 14px;
  margin-top: 1rem;
  margin-left: 23%;
  cursor: pointer;
  margin-bottom: 5%;
}

.feat-title {
  text-align: center;
  font-size: 28px;
  font-family: "Quicksand", sans-serif;
}

.copyright {
  margin-left: 68%;
  color: rgb(29, 57, 82);
  font-size: 15px;
}
