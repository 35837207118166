.createChannelBtn {
  font-family: "Roboto", sans-serif;
  border-radius: 7px;
  background-color: white;
  cursor: pointer;
  color: white;
  border-radius: 8px;
  height: 5%;
  border: none;
  background-image: radial-gradient(
    circle 732px at 96.2% 89.9%,
    rgba(70, 66, 159, 1) 0%,
    rgba(187, 43, 107, 1) 92%
  );
  width: 100px;
  margin-top: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.createChanneForm {
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.edit-channel {
  font-family: "Quicksand", sans-serif;
  background-image: radial-gradient(
    circle 732px at 96.2% 89.9%,
    rgba(70, 66, 159, 1) 0%,
    rgba(187, 43, 107, 1) 92%
  );
  border: 0;
  border-radius: 0.2rem;
  padding: 2px;
  color: white;
  font-size: 14px;
  margin-top: 0.5rem;
  margin-left: 20px;
  cursor: pointer;
}

.edit-channel:disabled:hover,
.edit-channel[disabled],
.createChannelBtn:disabled:hover,
.createChannelBtn[disabled] {
  background-color: #cccccc;
  color: #9e9797;
}

.edit-channel:hover {
  color: black;
}
