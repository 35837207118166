.memberli > .memberli1 {
  color: white;
}

.memberli {
  list-style-type: none;
  margin: 1rem;
  cursor: default;
}

.memberli1 {
  text-decoration: none;
}

.memberli1:hover {
  font-size: larger;
}

.memberUl {
  padding-left: 0;
}
