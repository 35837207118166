.login-container {
  height: auto;
  font-size: 18px;
  border-radius: 5px;
  width: 600px;
  text-align: center;
  color: black;
  padding: 15%;
}

.field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-top: 10%;
  height: 50%;
}

.field-input {
  width: 90%;
  height: 20px;
  font-size: 16px;
}

.login-but-grp {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.login-title,
.login-text,
.label {
  font-family: Roboto, sans-serif;
}

.label {
  font-weight: bold;
  line-height: 2.5em;
}

.submit-btn {
  font-size: 16px;
  margin-bottom: 8px;
  margin-top: 8px;
  width: 92%;
  height: 44px;
  background-color: rgb(63, 63, 186);
  color: white;
  cursor: pointer;
  border-radius: 8px;
}

.submit-btn:hover {
  background-color: rgb(74, 74, 194);
}
