.logoutBtn {
  font-family: "Quicksand", sans-serif;
  background-image: radial-gradient(
    circle 732px at 96.2% 89.9%,
    rgba(70, 66, 159, 1) 0%,
    rgba(187, 43, 107, 1) 92%
  );
  /* width: 120%; */
  border: 0;
  border-radius: 0.2rem;
  padding: 13px;
  color: white;
  font-size: 15px;
  margin-left: 60%;
}

.logoutBtn:hover {
  border: var(--light-white) 1px solid;
  cursor: pointer;
}
