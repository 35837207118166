.splash-container {
  height: 80vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: var(--wecord-purple);
  background-image: url("./e6d57714479874c665b36c7adee76b1d.png");
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative;
}

.splash-logo {
  margin-left: 2.5%;
}

.splash-logo:hover {
  background-color: rgba(62, 3, 62, 0.3);
  border-radius: 3px;
}

#splash-logged-out {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 0.8em;
  margin-right: 2em;
  justify-content: space-between;
}

.splash-nav {
  background-color: rgb(74, 87, 225);
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.3em;
  width: 100%;
  height: 7rem;
}

.bg-image-partition {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background: var(--partition);
  background-color: transparent;

  background-image: url("./8a8375ab7908384e1fd6efe408284203.png"),
    url("./c40c84ca18d84633a9d86b4046a91437.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right, left;
  margin-top: 5%;
  margin-bottom: 5%;
}

.background-partition {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: transparent;

  /* background: var(--partition); */
  /* background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(166, 22, 139, 1) 56%,
    rgba(0, 212, 255, 1) 100%
  ); */
  /* background: linear-gradient(
    90deg,
    rgb(11, 7, 84) 0%,
    rgb(44, 20, 183) 56%,
    rgb(13, 67, 78) 100%
  ); */
  /* background-color: var(--wecord-purple);
  background-image: url("./8a8375ab7908384e1fd6efe408284203.png"),
    url("./c40c84ca18d84633a9d86b4046a91437.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right, left bottom;
  position: relative; */
  /* margin-top: 10vh; */
}

#splash-title {
  color: white;
  font-size: 4em;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

#splash-intro {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 1.4em;
}

.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: var(--dark-purple);
  background-color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  color: black;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 5px solid #8ea1e1;
  border-bottom: 5px solid #8ea1e1;
}

.profile-pic {
  width: 70px;
  height: 70px;
  border-radius: 100%;
}

.dev-info {
  margin-top: auto;
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.git-in {
  margin-top: 15%;
}

.fa-github,
.fa-linkedin-in,
.fa-envelope {
  margin: 7px;
  color: black;
}

.name {
  margin-left: 7px;
  position: relative;
  top: 15%;
  font-weight: bold;
}

a:visited {
  color: black;
}
