.navbarcontainer {
  color: white;
  background-color: #2f3136;
  margin: 0;
  display: flex;
  /* justify-content: center; */
  justify-content: space-between;
  align-items: center;
  height: 10vh;
}

#splash-logo {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 30px;
  color: white;
  text-decoration: none;
  padding: 13px;
}

#splash-wecord {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 40px;
  color: white;
  text-decoration: none;
  margin-left: 2.5%;
  background-color: rgb(74, 87, 225);
  padding: 13px;
}

#splash-nav {
  color: white;
  text-decoration: none;
}

.navlink {
  color: white;
  text-decoration: none;
}

.navbarli {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Quicksand, sans-serif;
}

.active {
  background-color: rgba(32, 34, 37, 0.4);
}

.navlink.navlink1 {
  color: white;
}

.navbarlist {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 65px;
  margin: 0;
}

.auth-button {
  padding: 20px;
  margin-bottom: 50px;
}

.login-button,
.signup-button {
  margin-top: 0.8em;
  padding: 7px 16px;
  border-radius: 40px;
  margin-right: 20px;
  font-size: 18px;
  cursor: pointer;
  line-height: 24px;
  color: rgb(83, 78, 78);
  background-color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  border: 0;
}

.login-button:hover,
.signup-button:hover {
  color: rgb(92, 92, 226);
}
