/* TODO Add site wide styles */
:root {
  --wecord-purple: rgb(74, 87, 225);
  --dark-purple: rgb(62, 3, 62);
  --partition: rgb(2, 0, 36);
  --light-white: #f2f2f2;
}

.error {
  list-style-type: none;
  font-size: small;
  color: red;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  list-style-type: "-";
}

.loading {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 3em;
  color: white;
}
